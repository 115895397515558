<template>
  <div class="product-basketbutton">
    <slot name="additionalconfig" :setAdditionalBasketConfig="setAdditionalBasketConfig" />
    <b-button class="basketbutton" variant="custom" @click="addToCart">
      <div class="basketbutton-quantity" @click.stop>
        <b-dropdown v-show="!quantity.customMode" :text="dropdownText" size="sm" dropup no-flip>
          <b-dropdown-item v-for="item in dropdownItems" :key="item.value" @click="setQuantity(item.type, item.value)">{{ item.text }}</b-dropdown-item>
        </b-dropdown>

        <b-input v-show="quantity.customMode" ref="quantityControl" v-model="quantity.value" @blur="toggleQuantityCustomMode(false)" />
      </div>

      <div class="basketbutton-text flex-fill">
        <slot />
      </div>
    </b-button>
  </div>
</template>

<script>
import { COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS, COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS } from '@/constants'

import { closest } from '@/assets/js/helper/array'

export default {
  name: 'ProductBuybutton',
  props: {
    productId: {
      type: String,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    quantityinfo: {
      type: Object,
      default: () => ({})
    },
    quantityPostFixSingular: {
      type: String,
      default: ''
    },
    quantityPostFixPlural: {
      type: String,
      default: ''
    },
    requiredAdditionalPropertiesValidation: {
      type: Object,
      default: () => ({})
    },
    errorToastTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      quantity: {
        customMode: false,
        value: null,
        displayText: '',
        tempValue: null
      },
      additionalConfigurations: {}
    }
  },
  computed: {
    quantityOptions () {
      // must be >= 1, fallback = 1
      const max = Math.trunc(Math.max(1, Number(this.quantityinfo.max || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.max)))
      // must be >= 1 and <= max, fallback = 1 or max
      const min = Math.trunc(Math.max(1, Math.min(max, Number(this.quantityinfo.min || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.min))))
      // must be >= 1, fallback = 1
      const step = Math.trunc(Math.max(1, Number(this.quantityinfo.step || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.step)))

      return {
        min,
        max,
        range: max - min,
        step
      }
    },
    quantitySteps () {
      // add 1 for initial step (e.g. quantityOptions.min)
      const stepCount = Math.trunc(this.quantityOptions.range / this.quantityOptions.step) + 1
      return Array
        .from(Array(stepCount).keys())
        .map(step => {
          const stepValue = (step === 0 ? this.quantityOptions.min : this.quantityOptions.min + step * this.quantityOptions.step)
          return {
            value: stepValue,
            text: stepValue +
              (stepValue === 1
                ? (this.quantityPostFixSingular ? ` ${this.quantityPostFixSingular}` : '')
                : (this.quantityPostFixPlural ? ` ${this.quantityPostFixPlural}` : ''))
          }
        })
    },
    quantityInit () {
      // must by one >= 1 and <= quantitySteps.length, fallback = 0 or quantitySteps.length - 1
      const initialStep = Number(Math.min(this.quantitySteps.length, Math.max(1, this.quantityinfo.initialStep || COMPONENT_PRODUCTBUYBUTTON_QUANTITY_DEFAULTS.initialStep))) - 1
      return this.quantitySteps[initialStep]
    },
    dropdownText () {
      return (this.quantity.displayText || '').toString()
    },
    dropdownItems () {
      return this.quantitySteps
        .slice(0, COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS).map(ddi => ({ type: 'number', value: ddi.value, text: ddi.text }))
        .concat(({ type: 'custom', text: this.quantitySteps.length > COMPONENT_PRODUCTBUYBUTTON_DROPDOWN_MAX_ITEMS ? this.$t(`${this.tPath}.customquantitylabel`) : [] }))
    }
  },
  methods: {
    setQuantity (type, value = this.quantityOptions.min) {
      if (type === 'number') {
        this.quantity.value = value
        this.quantity.displayText = this.quantitySteps.find(x => x.value === value)?.text
      } else {
        this.toggleQuantityCustomMode(true)
      }
    },
    toggleQuantityCustomMode (state = false) {
      if (state) {
        this.quantity.tempValue = this.quantity.value
        this.quantity.value = null
        this.quantity.customMode = true

        this.$nextTick(() => {
          this.$refs.quantityControl.focus()
        })
      } else {
        const quantityValue = Number(this.quantity.value)
        const isNonNumberic = !/^-?([0-9.]){1,}$/.test(this.quantity.value)
        const isOutOfRange = quantityValue < this.quantityOptions.min && quantityValue > this.quantityOptions.max
        const isOutOfSteps = !this.quantitySteps.some(itm => itm.value === quantityValue)

        if (isNonNumberic || isOutOfRange) {
          this.quantity.value = this.quantity.tempValue
          this.quantity.tempValue = null
          this.quantity.customMode = false
        } else if (isOutOfSteps) {
          this.quantity.value = closest(this.quantitySteps.map(x => x.value), quantityValue)
        }
      }
    },
    addToCart () {
      let additionalConfigs = null
      const validationErrors = []
      if (Object.keys(this.additionalConfigurations)) {
        additionalConfigs = this.additionalConfigurations
      }
      if (Object.keys(this.requiredAdditionalPropertiesValidation).length !== 0) {
        for (var key in this.requiredAdditionalPropertiesValidation) {
          if (additionalConfigs[key] === null || additionalConfigs[key] === undefined) {
            validationErrors.push(this.requiredAdditionalPropertiesValidation[key])
          }
        }
      }
      if (validationErrors.length > 0) {
        validationErrors.forEach((error, i) => this.$bvToast.toast(error, {
          title: this.errorToastTitle,
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true
        }))
        return
      }
      this.$store.dispatch('shoppingcart/addItem', { productId: this.productId, productType: this.productType, additionalConfigurations: additionalConfigs, quantity: this.quantity.value })
      this.quantity.value = this.quantityInit.value
      this.quantity.displayText = this.quantityInit.text
      this.quantity.customMode = false
    },
    setAdditionalBasketConfig (additionalConfigs) {
      Object.keys(additionalConfigs).forEach(key => {
        this.additionalConfigurations[key] = additionalConfigs[key]
      })
    }
  },
  created () {
    this.quantity.value = this.quantityInit.value
    this.quantity.displayText = this.quantityInit.text
  }
}
</script>

<style lang="scss">
$productbasketbutton-padding-y: $btn-padding-y * 0.4 !default;
$productbasketbutton-padding-x: $productbasketbutton-padding-y !default;
$productbasketbutton-bg: $primary !default;
$productbasketbutton-border: transparent !default;
$productbasketbutton-font-weight: $font-weight-bold !default;
$productbasketbutton-line-height: 1.1 !default;
$productbasketbutton-hover-bg: darken($productbasketbutton-bg, 5%) !default;
$productbasketbutton-hover-border: darken($productbasketbutton-border, 5%) !default;

$productbasketbutton-quantity-width: $spacer * 4 !default;
$productbasketbutton-quantity-height: $input-height-sm !default;

$productbasketbutton-quantity-toggle-padding-y: 0 !default;
$productbasketbutton-quantity-toggle-padding-x: $btn-padding-x * 0.5 !default;
$productbasketbutton-quantity-toggle-bg: darken($productbasketbutton-bg, 2.5%) !default;
$productbasketbutton-quantity-toggle-border: darken($productbasketbutton-border, 2.5%) !default;
$productbasketbutton-quantity-toggle-hover-bg: darken($productbasketbutton-quantity-toggle-bg, 6%) !default;
$productbasketbutton-quantity-toggle-hover-border: darken($productbasketbutton-quantity-toggle-border, 6%) !default;

$productbasketbutton-quantity-menu-bg: $productbasketbutton-bg !default;
$productbasketbutton-quantity-menu-border: $productbasketbutton-border !default;
$productbasketbutton-quantity-menu-caret-size: $spacer * 0.6 !default;
$productbasketbutton-quantity-menu-item-padding-y: $dropdown-item-padding-y !default;
$productbasketbutton-quantity-menu-item-padding-x: $dropdown-item-padding-x * 0.6 !default;
$productbasketbutton-quantity-menu-item-hover-bg: $productbasketbutton-hover-bg !default;

$productbasketbutton-quantity-control-padding-y: $productbasketbutton-quantity-toggle-padding-y !default;
$productbasketbutton-quantity-control-padding-x: $productbasketbutton-quantity-toggle-padding-x !default;
$productbasketbutton-quantity-control-bg: $productbasketbutton-quantity-toggle-bg !default;
$productbasketbutton-quantity-control-border: $productbasketbutton-quantity-toggle-bg !default;
$productbasketbutton-quantity-control-hover-bg: $productbasketbutton-quantity-toggle-hover-bg !default;
$productbasketbutton-quantity-control-hover-border: $productbasketbutton-quantity-toggle-hover-border !default;
$productbasketbutton-quantity-control-focus-bg: $productbasketbutton-quantity-toggle-hover-bg !default;
$productbasketbutton-quantity-control-focus-border: $white !default;

$productbasketbutton-text-padding-y: 0 !default;
$productbasketbutton-text-padding-x: $spacer !default;

.product-basketbutton {
  .basketbutton {
    @include button-variant($productbasketbutton-bg, $productbasketbutton-border, $productbasketbutton-hover-bg, $productbasketbutton-hover-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $productbasketbutton-padding-y $productbasketbutton-padding-x;
    width: 100%;
    font-weight: $productbasketbutton-font-weight;
    line-height: $productbasketbutton-line-height;

    .basketbutton-quantity {
      height: $productbasketbutton-quantity-height;
      max-width: 50%;

      .dropdown {
        width: 100%;
        height: 100%;

        .dropdown-toggle {
          @include button-variant($productbasketbutton-quantity-toggle-bg, $productbasketbutton-quantity-toggle-border, $productbasketbutton-quantity-toggle-hover-bg, $productbasketbutton-quantity-toggle-hover-border);
          padding-top: $productbasketbutton-quantity-toggle-padding-y;
          padding-bottom: $productbasketbutton-quantity-toggle-padding-y;
          padding-left: $productbasketbutton-quantity-toggle-padding-x;
          height: 100%;
          background-image: escape-svg(str-replace($dropdown-toggle-icon, "fill='#{$dropdown-toggle-color}'", "fill='#{color-yiq($productbasketbutton-quantity-toggle-bg)}'"));
          box-shadow: none !important;
        }

        .dropdown-menu {
          margin-bottom: 0;
          margin-left: 50%;
          min-width: 0;
          background-color: $productbasketbutton-quantity-menu-bg;
          border-color: $productbasketbutton-quantity-menu-border;
          color: color-yiq($productbasketbutton-quantity-menu-bg);
          transform: translate3d(-50%, calc(-100% - (#{$productbasketbutton-padding-y} + #{$productbasketbutton-quantity-menu-caret-size} + 2px)), 0) !important;

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-width: $productbasketbutton-quantity-menu-caret-size $productbasketbutton-quantity-menu-caret-size 0;
            border-style: solid solid none;
            border-color: $productbasketbutton-quantity-menu-bg transparent transparent;
            transform: translateX(-50%);
          }

          .dropdown-item {
            padding: $productbasketbutton-quantity-menu-item-padding-y $productbasketbutton-quantity-menu-item-padding-x;
            color: inherit;

            &:hover {
              background-color: $productbasketbutton-quantity-menu-item-hover-bg;
            }
          }
        }
      }

      .form-control {
        padding: $productbasketbutton-quantity-control-padding-y $productbasketbutton-quantity-control-padding-x;
        height: 100%;
        background-color: $productbasketbutton-quantity-control-bg;
        border-color: $productbasketbutton-quantity-control-border;
        border-radius: $dropdown-border-radius;
        color: color-yiq($productbasketbutton-quantity-control-bg);
        text-align: center;
        transition: $transition-base;

        &:focus {
          background-color: $productbasketbutton-quantity-control-focus-bg !important;
          border-color: $productbasketbutton-quantity-control-focus-border !important;
        }
      }
    }

    .basketbutton-text {
      padding: $productbasketbutton-text-padding-y $productbasketbutton-text-padding-x;
    }

    &:hover {
      .basketbutton-quantity {
        .dropdown {
          .dropdown-toggle {
            color: color-yiq($productbasketbutton-quantity-toggle-hover-bg);
            @include gradient-bg($productbasketbutton-quantity-toggle-hover-bg);
            border-color: $productbasketbutton-quantity-toggle-hover-border;
          }
        }

        .form-control {
          background-color: $productbasketbutton-quantity-control-hover-bg;
          border-color: $productbasketbutton-quantity-control-hover-bg;
        }
      }
    }
  }
}
</style>
